"use client";

import {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionItem,
  FaqAccordionTrigger,
} from "@/components/ui/faqAccordion";

type Props = {
  expanded: boolean;
  structuredData: boolean;
  items: [
    {
      id: number;
      heading: string;
      panel: string;
    }
  ];
};

const Accordion = ({ expanded, structuredData, items }: Props) => {
  return (
    <FaqAccordion
      type="multiple"
      className="article-container"
      // collapsible={true}
      // defaultValue={["item-1", "item-2", "item-3"]}
    >
      {items.map((item) => (
        <FaqAccordionItem
          value={`item-${item.id}`}
          className="border-primary-300 px-4"
        >
          <FaqAccordionTrigger>{item.heading}</FaqAccordionTrigger>
          <FaqAccordionContent className="text-container">
            <span dangerouslySetInnerHTML={{ __html: item.panel }} />
          </FaqAccordionContent>
        </FaqAccordionItem>
      ))}
    </FaqAccordion>
  );
};

export default Accordion;
