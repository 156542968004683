"use client";

import CImage from "@/components/CImage";
import CustomInput from "@/components/generator/fields/CustomInput";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader2, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const FormSchema = z.object({
  name: z.string().min(3, {
    message: "Der Name muss mindestens 3 Zeichen lang sein.",
  }),
  email: z.string().email("Dies ist keine gültige E-Mail."),
});
type Inputs = z.infer<typeof FormSchema>;
const Newsletter = (props: any) => {
  const form = useForm<Inputs>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
  });

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors },
  } = form;
  function onSubmit(values: z.infer<typeof FormSchema>) {
    sendValuesToApi(values);
  }

  const sendValuesToApi = async (values: z.infer<typeof FormSchema>) => {
    setIsSending(true);
    console.log(values);
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_DOMAIN_ROUTE}/api/newsletter/`,
        {
          method: "POST",
          body: JSON.stringify(values),
        }
      );
      let resp = await response.json();
      setIsSuccess(resp.success);
    } catch (e) {
      setIsSuccess(false);
    }
    setIsSent(true);
    setIsSending(false);
    // disableReply();
    // loadBook();
  };

  return (
    <div className="flex w-full flex-col border border-primary-400 bg-white">
      <CImage
        src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1715803971/Newsletter_picture_new_75ddc7db6f.jpg"
        width={360}
        height={220}
        crop
        className="aspect-video w-full object-cover"
      />
      <div className="w-full bg-white p-5">
        <h5 className="mb-4">Benu-Newsletter</h5>
        <p>
          Neuigkeiten rund um Benu und besondere Highlights und Aktionen direkt
          in Ihr Postfach.
        </p>
        {!isSent ? (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mx-auto flex w-full flex-col items-center justify-center gap-2 pt-6"
            >
              <CustomInput
                form={form}
                name="name"
                textError="text-primary/50"
                label="Ihr Name"
                placeholder="Vor- und Nachname"
              />
              <CustomInput
                form={form}
                name="email"
                textError="text-primary/50"
                label="Wohin gehen die News?"
                placeholder="Ihr Email"
              />

              <div className="flex w-full flex-col items-center justify-between gap-2 pt-2 lg:flex-row">
                <button
                  type="submit"
                  className="btn secondary large solid w-full lg:w-full"
                >
                  {isSending && <Loader2 className="h-4 w-4 animate-spin" />}
                  Anmelden
                </button>
              </div>
            </form>
          </Form>
        ) : isSent && isSuccess ? (
          <div className="flex w-full flex-col items-center justify-center pt-6">
            <span className="flex h-16 w-16 items-center justify-center rounded-full bg-primary text-white">
              <Check className="h-8 w-8" />
            </span>
            <h5 className="text-primary">Erfolg!</h5>
            <p className="pt-2 text-center">
              Vielen Dank für Ihre Nachricht! Wir werden uns in Kürze bei Ihnen
              melden.
            </p>
          </div>
        ) : isSent && !isSuccess ? (
          <div className="flex w-full flex-col items-center justify-center pt-6">
            <span className="flex h-16 w-16 items-center justify-center rounded-full bg-tertiary text-white">
              <X className="h-8 w-8" />
            </span>
            <h5 className="text-tertiary">Fehler!</h5>
            <p className="pt-2 text-center">
              Da ist etwas schiefgegangen, bitte versuchen Sie es später noch
              einmal.
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
