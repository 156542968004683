"use client";
import SelectSidebarComponent from "./components/SelectSidebarComponent";

const DesktopSidebarItems = (sidebar: any) => {
  return (
    <div className="order-2 hidden w-full flex-col gap-8 pt-8 lg:flex lg:max-w-[300px] xl:max-w-[320px] 2xl:max-w-[340px]">
      {sidebar.sidebar.map((item: any) => (
        <SelectSidebarComponent {...item} />
      ))}
    </div>
  );
};

export default DesktopSidebarItems;
