import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/articles/components/Accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/articles/components/SidebarNavigation/DesktopSidebarItems.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/articles/components/ToC.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/articles/components/YoutubePlayer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CImage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/error/ItemError.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/Footer/FooterDesktopList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopNavBarPhone.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/LoginButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileUserMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/UserDropDownMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/PhoneBar/PhoneBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/slider/ImageSliderMobile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dropdown-menu.tsx")