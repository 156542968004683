import CImage from "@/components/CImage";
import { ArrowRight } from "lucide-react";
import Link from "next/link";

type Props = {};

const ProductItem = (props: any) => {
  return (
    <Link
      className="group w-full border border-secondary-400 bg-white"
      href={`/produkte/${props.product.slug}/`}
    >
      <CImage
        src={props.product.sidebar.image.url}
        width={340}
        height={300}
        crop
      />
      <div className="flex flex-col gap-2 bg-white p-5 text-secondary-900 transition-colors duration-300 group-hover:bg-secondary-100">
        <h5>{props.product.name}</h5>
        <p
          className="pt-2"
          dangerouslySetInnerHTML={{
            __html: props.product.sidebar.description,
          }}
        />
        <div className="flex flex-row items-center gap-2 pt-4 text-lg font-bold">
          Zur Auswahl
          <ArrowRight className="ml-0 h-6 w-6 transition-all duration-300 group-hover:ml-2" />
        </div>
      </div>
    </Link>
  );
};

export default ProductItem;
