import Icons from "@/components/Icons";
import { IconList } from "@/types/icons";
import Link from "next/link";
import React from "react";

type Props = {};

const FuneralList = (props: any) => {
  return (
    <div className="flex w-full flex-col divide-y divide-secondary-200 border border-secondary-300 bg-white px-4 py-2">
      {props.funeralTypes.map((item: any) => (
        <Link className="group flex flex-row gap-4 py-2" href={item.link}>
          <div className="sidebar-icon flex h-16 w-16 items-center justify-center rounded-lg bg-secondary text-white transition-colors duration-300 group-hover:bg-secondary-800">
            <Icons icon={item.funeralType as IconList} />
          </div>
          <div className="flex flex-col">
            <p className="-my-0.5 text-[18px] font-bold">{item.label}</p>
            <p className="-my-0.5 text-[16px]">
              ab 
              <span className="-my-0.5 text-[18px] font-bold">
                {item.plans[0].price} €
              </span>{" "}
            </p>
            <p className="-my-0.5 text-[16px]">exkl. externe Kosten</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default FuneralList;
