import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomLabel from "./CustomLabel";

type Props = {
  form: any;
  name: string;
  label: string;
  placeholder: string;
  bgError?: string;
  textError?: string;
  type?: any;
  pattern?: string;
  ref?: string;
  inputmode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal";
};

const CustomInput = ({
  form,
  name,
  placeholder,
  label,
  bgError,
  textError,
  type,
  pattern,
  inputmode,
}: Props) => {
  return (
    <div className="grid w-full items-center gap-1.5">
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <CustomLabel id={name} label={label} />
            <FormControl>
              <Input
                key={field.name}
                placeholder={placeholder}
                id={field.name}
                type={type}
                max={type === "date" ? "2999-12-31" : ""}
                multiple={type === "file" ? true : false}
                {...field}
                className={`${bgError ? bgError : "aria-invalid:bg-red/10"} ${
                  type === "date" ? "tracking-tighter" : ""
                }`}
              />
            </FormControl>
            <FormMessage className={`${textError ? textError : "text-red"}`} />
          </FormItem>
        )}
      />
    </div>
  );
};

export default CustomInput;
