import { Label } from "@/components/ui/label";
type Props = {
  id: string;
  label: string;
  white?: true;
};
const CustomLabel = ({ id, label, white }: Props) => {
  return (
    <Label
      htmlFor={id}
      className={`text-caption-1 font-semibold uppercase  tracking-widest subpixel-antialiased ${
        white ? "text-white" : ""
      }`}
    >
      {label}
    </Label>
  );
};

export default CustomLabel;
