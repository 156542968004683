import FuneralList from "./FuneralList";
import MainFuneral from "./MainFuneral";
import MonthPlan from "./MonthPlan";
import Newsletter from "./Newsletter";
import ProductItem from "./ProductItem";

const SelectSidebarComponent = (props: any) => {
  if (props.__component === "sidebar.funeral") {
    return <MainFuneral {...props} />;
  } else if (props.__component === "sidebar.month-plans") {
    return <MonthPlan {...props} />;
  } else if (props.__component === "sidebar.funeral-list") {
    return <FuneralList {...props} />;
  } else if (props.__component === "sidebar.products") {
    return <ProductItem {...props} />;
  } else if (props.__component === "sidebar.newsletter") {
    return <Newsletter {...props} />;
  } else {
    return <></>;
  }
};

export default SelectSidebarComponent;
