import CImage from "@/components/CImage";
import { ArrowRight } from "lucide-react";
import Link from "next/link";
import React from "react";

type Props = {};

const MainFuneral = (props: any) => {
  return (
    <Link
      className="group w-full border border-secondary-400 bg-white"
      href={props.funeral_type.link}
    >
      <CImage
        src={props.funeral_type.image.url}
        width={340}
        height={300}
        crop
      />
      <div className="flex flex-col gap-2 bg-secondary-600 p-5 text-white transition-colors duration-300 group-hover:bg-secondary-700">
        <h4>{props.funeral_type.label}</h4>
        <p className="text-[17px]">
          ab 
          <span className="text-2xl font-bold">
            {props.funeral_type.plans[0].price} €
          </span>{" "}
          - exkl. externe Kosten
        </p>
        <p className="pt-2">{props.funeral_type.plans[0].serviceTitle}</p>
        <div className="flex flex-row items-center gap-2 pt-4 text-lg font-bold">
          Jetzt planen
          <ArrowRight className="ml-0 h-6 w-6 transition-all duration-300 group-hover:ml-2" />
        </div>
      </div>
    </Link>
  );
};

export default MainFuneral;
