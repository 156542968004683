"use client";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";
import { AiOutlinePlus } from "react-icons/ai";

import { cn } from "@/lib/utils";

const FaqAccordion = AccordionPrimitive.Root;

const FaqAccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      "flex flex-col justify-center border-b border-primary-200 py-4 text-[20px] text-black-800 last:border-none",
      className
    )}
    {...props}
  />
));
FaqAccordionItem.displayName = "AccordionItem";

const FaqAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between text-left text-lg font-semibold transition-all duration-150 [&[data-state=open]>svg]:rotate-45",
        className
      )}
      {...props}
    >
      {children}
      <AiOutlinePlus className="h-5 w-5 shrink-0 text-primary transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
FaqAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const FaqAccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "mt-4 overflow-hidden text-base transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className
    )}
    {...props}
  >
    <div>{children}</div>
  </AccordionPrimitive.Content>
));
FaqAccordionContent.displayName = AccordionPrimitive.Content.displayName;

export {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionItem,
  FaqAccordionTrigger,
};
