"use client";

import { scrollToElement } from "@/utils/scroll";
import { ChevronDown, ChevronRight } from "lucide-react";
import React, { useState } from "react";

type Props = {};

const ToC = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const toogleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="flex w-full flex-col pb-4">
      <div
        className="flex cursor-pointer flex-row items-center justify-between bg-primary-800 p-4 px-6 text-lg font-bold text-white lg:text-xl"
        onClick={() => {
          toogleOpen();
        }}
      >
        {props.title}
        <ChevronDown
          className={`h-6 w-6 text-white transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      <div
        className={`overflow-hidden bg-white p-0 transition-all duration-300 ${
          isOpen ? "h-auto" : "h-0"
        }`}
      >
        <div
          className={`flex flex-col gap-1 p-4 transition-all duration-300 ${
            isOpen ? "h-auto" : "h-0"
          }`}
        >
          {props.navigation.map((item: any) => (
            <div
              className="group cursor-pointer hover:bg-primary-200"
              onClick={() => {
                scrollToElement(item.anchor);
              }}
            >
              {item.type === "h2" ? (
                <span className="flex flex-row items-center gap-1 py-0.5 pl-0 text-lg font-semibold text-primary-800">
                  <ChevronRight className="h-5 w-5" />
                  <p>{item.label}</p>
                </span>
              ) : (
                <span className="flex flex-row items-center gap-1 py-0.5 pl-3 font-semibold text-primary-700">
                  <ChevronRight className="h-4 w-4" />
                  <p>{item.label}</p>
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToC;
